import React, { useState, useEffect, Suspense, lazy } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { HashRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import ContactUs from "./components/ContactUs/ContactUs";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ErrorBoundary from "./components/ErrorHandling/ErrorBoundary";

const Home = lazy(() => import("./components/Home/Home"));
const About = lazy(() => import("./components/About/About"));
const Projects = lazy(() => import("./components/Projects/Projects"));
const Resume = lazy(() => import("./components/Resume/Resume"));
const AuthDetails = lazy(() => import("./components/Auth/AuthDetails"));
const VisitedRestaurants = lazy(() => import("./components/Star/FoodBlog/VisitedRestaurants"));
const ViewUnvisitedRestaurants = lazy(() => import("./components/Star/FoodBlog/ViewUnvisitedRestaurants"));
const GitHubMetrics = lazy(() => import("./components/Star/GitHub/GitHubMetrics"));
const LeetCodeView = lazy(() => import("./components/Star/LeetCode/LeetCodeView"));
const Certificates = lazy(() => import("./components/Certificates/Certificates"))
function App() {
  const [load, updateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <br /><br />
        <div className="main-content">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/project" element={<Projects />} />
              <Route path="/about" element={<About />} />
              <Route path="/resume" element={<Resume />} />
              <Route path="/contact" element={<ContactUs/>} />
              <Route path="/secret" element={<AuthDetails />} />
              <Route path="/github" element={<GitHubMetrics />} />
              <Route path="/leetcode" element={<LeetCodeView />} />
              <Route path="/forgot-password" element={<ForgotPassword/>} />
              <Route path="/foodwishlist" element={<ViewUnvisitedRestaurants />} />
              <Route path="/foodblog" element={<VisitedRestaurants />} />
              <Route path="/certificates" element={<Certificates/>}/>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        </div>
        <ErrorBoundary>
          <Footer />  
        </ErrorBoundary>
      </div>
    </Router>
  );
}

export default App;
