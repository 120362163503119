import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyBDFtmz1ZV4p98bCL_0Gh3r9lHwB80AZO8",
  authDomain: "myportfolio-f60f8.firebaseapp.com",
  projectId: "myportfolio-f60f8",
  storageBucket: "myportfolio-f60f8.appspot.com",
  messagingSenderId: "625400820661",
  appId: "1:625400820661:web:dfbc2a94c5757c4258dffc",
  measurementId: "G-CEECV901PH",
  databaseURL: "https://myportfolio-f60f8-default-rtdb.europe-west1.firebasedatabase.app/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);
export const storage = getStorage(app);